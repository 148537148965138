import { onAuthStateChanged } from "firebase/auth";
import { arrayUnion, doc, onSnapshot, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { auth, db } from "../../../firebase.config";
import GSTTable from "./gstTable";
import PartyDisclosure from "./partyDisclosure";
import { showToast } from "../../../utils/toast";
import { useNavigate } from "react-router-dom";
import { Input } from "../../reusable/input";
import Termsandcondition from "../../reusable/modal/termsandcondition";
import ReactToPrint from "react-to-print";
import { SectionFour, SectionOne, SectionThree, SectionTwo } from "./sections";

const Trainee = () => {
  const initialstate = [
    { name: "TV", isChecked: false },
    { name: "Print", isChecked: false },
    { name: "Radio", isChecked: false },
    { name: "Digital", isChecked: false },
    { name: "OOH", isChecked: false },
    { name: "Content", isChecked: false },
    { name: "Influencer", isChecked: false },
    { name: "Sponserships and Events", isChecked: false },
    { name: "Activation", isChecked: false },
    { name: "Sports Partnership", isChecked: false },
    { name: "Talent Management", isChecked: false },
    { name: "Overheads", isChecked: false },
  ];
  const [user, setuser] = useState();
  const [data, setdata] = useState();
  const [radioArray, setradioarray] = useState(initialstate);
  const [showModal, setshowModal] = useState(false);
  const [recall, setrecall] = useState(false);
  const [isEditable, setisEditable] = useState(false);

  const navigate = useNavigate();

  const scroll = (search) => {
    if (search.includes("One")) {
      window.scrollBy(0, 550);
    }
    if (search.includes("Two")) {
      window.scrollBy(0, 622);
    }
    if (search.includes("Three")) {
      window.scrollBy(0, 700);
    }
    if (search.includes("Four")) {
      window.scrollBy(0, 760);
    }
    if (search.includes("Five")) {
      window.scrollBy(0, 830);
    }
    if (search.includes("Six")) {
      window.scrollBy(0, 900);
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      const path = window.location.hash.replace("#", "");
      let accord = document.getElementById(path);
      const isCollapse = accord.classList.contains("collapse");
      if (isCollapse) {
        accord.classList.remove("collapse");
        accord.classList.add("collapsed");
        scroll(path);
      }
    }
  }, [window.location.hash]);

  useEffect(() => {
    if (recall) {
      handleSubmit()
    }
  }, [recall === true]);

  // handle submit
  const handleSubmit = async () => {
    if (auth.currentUser && auth.currentUser.uid) {
      await updateDoc(doc(db, "users", user.uid), {
        "forms.vendorform": 1
      });
      // const currentDate = Date.now();
      // const log = {
      //   title: `${user.displayName} submitted Trainee Form`,
      //   timestamp: currentDate,
      // }
      // await updateDoc(doc(db, "users", user.uid), {
      //   "logs": arrayUnion(log),
      // });
      showToast("success", "Trainee Details Saved Successfully");
      setisEditable(true);
      navigate('/dashboard');
    }
  };

  // to get the prefilled form
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setuser(user);
      }
    });
  }, []);

  useEffect(() => {
    if (user?.uid) {
      onSnapshot(doc(db, "users", user?.uid), (doc) => {
        if (doc.data().forms?.vendorform > 0) {
          setisEditable(true);
        }
        setradioarray(doc.data()?.vendorTypes?.length > 0 ? doc.data()?.vendorTypes : initialstate);
        setdata(doc.data());
      });
    }
  }, [user]);

  const printDivRef = useRef();

  const handleSubmitForm = () => {
    try {
      if (data && data?.vendorDetails) {
        const { sectionOne, sectionSix } = data?.vendorDetails;
        if (sectionOne == undefined || sectionSix == undefined) {
          showToast('error', "Please fill in complete form");
          return;
        } else {
          setshowModal(true);
        }
      } else {
        showToast('error', "Please fill in complete form");
        return;
      }
    } catch (e) { }
  }
  return (
    <>
      <div ref={printDivRef} id="printDiv" className="p-4 w-full h-full rounded-lg border-gray-800 glass">
        <h3 className="text-white p-4 text-center text-uppercase lg:text-3xl md:text-xl">
          Trainee Registration Form
        </h3>
        <div id="printableDIV">
          <div
            className="accordion accordion-flush text-white"
            id="accordionExample"
          >
            <SectionOne
              isEditable={isEditable}
              user={user}
              vtype={radioArray}
            />
            <SectionTwo />
            <SectionThree />
            <SectionFour
              isEditable={isEditable}
              user={user}
              isMT={true}
            />

            {data && data?.forms && data?.forms?.vendorform == 0 && (
              <div className="my-3 d-flex ml-auto">
                <button
                  className="btn btn-primary rounded-lg w-100"
                  onClick={handleSubmitForm}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
        {data && data?.forms?.vendorform === 2 ?
          <>
            <ReactToPrint
              trigger={() => {
                return <button id="printbtn" className='rounded-lg bg-blue-800 text-white w-full p-2 my-3'>Print</button>
              }}
              onBeforeGetContent={() => {
                document.getElementById('printbtn').classList.add('d-none');
                document.getElementById('printDiv').classList.remove('glass');
                document.getElementById('collapseSectionOne').classList.add('show');
                document.getElementById('collapseSectionTwo').classList.add('show');
                document.getElementById('collapseSectionThree').classList.add('show');
                document.getElementById('collapseSectionFour').classList.add('show');
              }}
              onAfterPrint={() => {
                document.getElementById('printbtn').classList.remove('d-none');
                document.getElementById('printDiv').classList.add('glass');
                document.getElementById('collapseSectionOne').classList.remove('show');
                document.getElementById('collapseSectionTwo').classList.remove('show');
                document.getElementById('collapseSectionThree').classList.remove('show');
                document.getElementById('collapseSectionFour').classList.remove('show');
              }}
              content={() => printDivRef.current}
              documentTitle="Trainee Data Form"
              pageStyle="print"
            />
          </>
          : ""}
      </div>
      <Termsandcondition
        title="Are you sure you want to submit it?"
        subtitle="I hereby declare that all the information given above is true and correct to the best of my knowledge"
        show={showModal}
        setrecall={setrecall}
        onClose={() => setshowModal(false)}
      />
    </>
  );
};

export default Trainee;
