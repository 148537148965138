import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth, db } from "../../firebase.config";
import DataTable, { createTheme } from "react-data-table-component";
import { getStatus } from "../../utils/utils";
import { doc, onSnapshot } from "firebase/firestore";
import { updateDocument } from "../../utils/api";

const Dashboard = () => {
  const [user, setUser] = useState();
  const [data, setdata] = useState();
  const [status, setstatus] = useState({})

  useEffect(() => {
    onAuthStateChanged(auth, (User) => {
      if (User) {
        setUser(User);
      } else {
        setUser();
      }
    });


  }, []);

  useEffect(() => {
    if (user) {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        const _tmp = doc.data();
        console.log(_tmp)
        setdata(_tmp);
        setstatus(_tmp?.forms);
      });
    }
  }, [user]);

  const rows = [
    {
      id: 1,
      name: "Non-applicability of GST",
      status: status?.nonapplicability,
      isApplicable: data?.isGSTApplicable,
    },
    {
      id: 2,
      name: "Trainee Data Form",
      status: status?.vendorform,
      isApplicable: ""
    },
    {
      id: 3,
      name: "Trainee Bank Details",
      status: status?.bankdetails,
      isApplicable: ""
    },
  ];

  const columns = [
    {
      name: "Sr. No.",
      selector: (row) => row.id,
      sortable: true,
      width: "105px",
    },
    {
      name: "Form Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => getStatus(row.status, row.isApplicable),
      sortable: true,
    },
  ];

  createTheme("solarized", {
    text: {
      primary: "#FFFFFF",
    },
    background: {
      default: "rgba(255, 255, 255, 0.07)",
    },
  });

  const customStyles = {
    headCells: {
      style: {
        fontSize: "16px",
        backgroundColor: "rgba(255,255,255,0.8)",
        backdropFilter: "blur(15px)",
        color: "black",
        borderRadiusTopLeft: "10px",
      },
    },
  };

  const setVendorStatus = async () => {
    if (
      data?.forms?.status == 0 &&
      data?.forms?.bankdetails == 1 &&
      data?.forms?.vendorform == 1 &&
      (data?.isEinvoicingApplicable == "no" && data?.forms?.einvoicing == 1) ||
      (data?.isGSTApplicable == "no" && data?.forms?.nonapplicability == 1) ||
      (data?.vendortype != "Overhead" && data?.forms?.msa == 1)
    ) {
      await updateDocument("users", user.uid, { "forms.status": 1 });
    }
  }

  useEffect(() => {
    setVendorStatus();
  }, [user, data?.forms])

  return (
    <>
      <div
        className="p-4 w-full h-full rounded-lg border-gray-800 shadow-md glass"
        style={{ height: "calc(100vh - 82px)" }}
      >
        <div className="text-white h1 break-all">Dashboard</div>
        {data?.forms?.approved &&
          <div class="alert alert-success" role="alert">
            Welcome Onboard, {data?.name}! Your Verification is Successful
          </div>}
        <span
          className={
            !user?.emailVerified
              ? "mb-2 bg-gray-100 text-gray-800 text-xl font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-300"
              : "d-none"
          }
        >
          <svg
            aria-hidden="true"
            className="mr-1 w-3=5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
              clipRule="evenodd"
            ></path>
          </svg>
          <small>Email Verification is pending, please check your mail</small>
        </span>
        {/* {user?.emailVerified && */}
        <DataTable
          columns={columns}
          data={rows}
          responsive
          highlightOnHover
          fixedHeader
          theme="solarized"
          customStyles={customStyles}
          fixedHeaderScrollHeight="500px"
        />
        {/* } */}
      </div>
    </>
  );
};

export default Dashboard;
