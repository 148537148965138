import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import PrivateRoutes from './utils/PrivateRoutes';
import Register from './components/pages/register';
import Login from './components/pages/login';
import Dashboard from './components/pages/dashboard';
import NavigationBars from './components/reusable/navigation';
import Profile from './components/pages/profile';
import Queries from './components/pages/queries';
import ExportGST from './components/forms/GST/exportGST';
import BankDetailsForm from './components/forms/vendorBankdetails/bankdetailsForm';
import store from './store/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NonApplicabilityGST from './components/forms/GST/nonApplicabilityGST';
import Approval from './components/pages/approval';
import Trainee from './components/forms/traineeForm/trainee';
import TraineeDocumentUpload from './components/forms/traineeForm/traineeDocumentUpload';

function App() {

  return (
    <>
      <Provider store={store}>
        <div className={sessionStorage.getItem('accessToken') ? 'App' : 'd-flex justify-content-center align-items-center'} style={{ height: "100%" }}>
          <BrowserRouter>
            <NavigationBars />
            <div className="d-flex justify-content-center align-items-center">
              <Routes>
                {/* Private/Vendor Routes */}
                <Route element={<PrivateRoutes />}>
                  <Route path='/dashboard' exact element={<Dashboard />} />
                  <Route path='/nonapplicability' element={<NonApplicabilityGST />} />
                  <Route path='/exportgst' element={<ExportGST />} />
                  {/* <Route path='/vendor-einvoicing' element={<ExportEInvoicing />} /> */}
                  <Route path='/trainee-bankdetails' element={<BankDetailsForm />} />
                  <Route path='/profile' element={<Profile />} />
                  <Route path='/queries' element={<Queries />} />
                  <Route path='/mt-trainee-form' element={<Trainee />} />
                  <Route path='/mt-upload-documents' element={<TraineeDocumentUpload />} />
                </Route>
                {/* Public Routes */}
                <Route path='/approve/:uid' element={<Approval />} />
                <Route path='/register' exact element={<Register />} />
                <Route path='/' exact element={<Login />} />
              </Routes>

            </div>
          </BrowserRouter>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </Provider>
    </>
  );
}

export default App;