import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../../../firebase.config';
import { showToast } from '../../../utils/toast';
import { Input } from '../../reusable/input'

const SectionOne = ({ isEditable, user, vtype }) => {
    const [secOne, setSecOne] = useState([]);
    const [generalObj, setgeneralobj] = useState({});
    const [generalList, setgenerallist] = useState([]);
    const [accountsDetails, setaccountsdetails] = useState({});
    const [salesDetails, setsalesdetails] = useState({});
    const [makerDetails, setmakerdetails] = useState({});
    const [data, setdata] = useState({
        name: "",
        pancard: "",
        aadharcard: "",
    });
    const [gstRows, setGSTData] = useState([
        {
            gstStateCode: "",
            gstNo: "",
            billingAddress: "",
            serviceName: "",
            contactDetails: "",
        },
    ]);

    useEffect(() => {
        if (user?.uid) {
            onSnapshot(doc(db, "users", user?.uid), (doc) => {
                const allData = doc.data()?.vendorDetails;
                setdata({
                    name: doc.data()?.name,
                    pancard: doc.data()?.pancard,
                    aadharcard: doc.data()?.aadharcard,
                });
                setSecOne({
                    ...secOne,
                    ...allData?.sectionOne,
                    nameOfVendor: doc.data()?.name,
                    panno: doc.data()?.pancard,
                    aadharcard: doc.data()?.aadharcard,
                });
                setSecOne(allData?.sectionOne);
                setaccountsdetails(allData?.sectionOne?.accountsDetails ? allData?.sectionOne?.accountsDetails : []);
                setsalesdetails(allData?.sectionOne?.salesDetails ? allData?.sectionOne?.salesDetails : []);
                setmakerdetails(allData?.sectionOne?.makerDetails ? allData?.sectionOne?.makerDetails : []);
                setGSTData(allData?.sectionOne?.gstTable ? allData?.sectionOne?.gstTable : []);
            });
        }
    }, [user]);

    // GST Table Functions
    const addGSTTableRows = () => {
        const rowsInput = {
            gstStateCode: "",
            gstNo: "",
            billingAddress: "",
            serviceName: "",
            contactDetails: "",
        };
        setGSTData([...gstRows, rowsInput]);
    };


    const deleteGSTTableRows = (index) => {
        const rows = [...gstRows];
        rows.splice(index, 1);
        setGSTData(rows);
    };

    const handleGSTChange = (index, e) => {
        const { name, value } = e.target;
        var rowsInput = [...gstRows];
        rowsInput[index][name] = value;
        setGSTData(rowsInput);
    };

    useEffect(() => {
        setSecOne({
            ...secOne,
            gstTable: gstRows,
        });
    }, [gstRows]);

    useEffect(() => {
        const keyPersonal = {
            accountsDetails,
            salesDetails,
            makerDetails,
        };
        setSecOne({
            ...secOne,
            ...keyPersonal,
        });
    }, [accountsDetails, salesDetails, makerDetails]);

    const handleSetState = (e, section) => {
        if (section === 1) {
            setSecOne({
                ...secOne,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSaveSection = async (section) => {
        if (section === 1) {
            await updateDoc(doc(db, "users", user.uid), {
                vendorTypes: vtype,
                "vendorDetails.sectionOne": secOne
            });
            showToast("success", "Section I Saved Successfully");
        }
    };

    const handleKeyPersonalData = (e) => {
        if (e.target.name.includes("accounts")) {
            setaccountsdetails({
                ...accountsDetails,
                [e.target.name]: e.target.value,
            });
        }
        if (e.target.name.includes("sales")) {
            setsalesdetails({
                ...salesDetails,
                [e.target.name]: e.target.value,
            });
        }
        if (e.target.name.includes("maker")) {
            setmakerdetails({
                ...makerDetails,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleGeneralQuestions = (e) => {
        setgeneralobj({
            ...generalObj,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (
            generalObj?.companyName != undefined &&
            generalObj?.refName != undefined &&
            generalObj?.refNumber != undefined &&
            typeof generalObj?.refNumber == "string" ||
            typeof generalObj?.refNumber == "number"
        ) {
            setgenerallist([...generalList, generalObj]);
            setgeneralobj({});
        }
    }, [generalObj]);

    useEffect(() => {
        if (generalList.length > 0) {
            setSecOne({
                ...secOne,
                generalQuestions: generalList,
            });
        }
    }, [generalList]);

    return (
        <form onSubmit={(e) => { e.preventDefault(); handleSaveSection(1); }}>
            <div className="accordion-item my-2 rounded-none glass shadow-none">
                <h2 className="accordion-header" id="secOne">
                    <button
                        className="accordion-button bg-transparent collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSectionOne"
                        aria-expanded="true"
                        aria-controls="collapseSectionOne"
                    >
                        <h3 className="text-white text-start text-uppercase text-lg ">
                            Section 1 - Trainee Information Details
                        </h3>
                    </button>
                </h2>
                <div
                    id="collapseSectionOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="secOne"
                    data-bs-parent="#accordionExample"
                >
                    <div className="accordion-body text-white">
                        <div className="my-3">
                            <div className="row mt-2 ">
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="nameOfVendor"
                                        label="Name of the Trainee"
                                        onChange={(e) => handleSetState(e, 1)}
                                        value={secOne?.nameOfVendor}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="addressL1"
                                        label="Address (Line 1)"
                                        onChange={(e) => handleSetState(e, 1)}
                                        value={secOne?.addressL1}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="addressL2"
                                        label="Address (Line 2)"
                                        onChange={(e) => handleSetState(e, 1)}
                                        value={secOne?.addressL2}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="addressL3"
                                        label="Address (Line 3)"
                                        onChange={(e) => handleSetState(e, 1)}
                                        value={secOne?.addressL3}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="city"
                                        label="City"
                                        onChange={(e) => handleSetState(e, 1)}
                                        value={secOne?.city}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="pincode"
                                        label="Pin/Zip/Post Code"
                                        onChange={(e) => handleSetState(e, 1)}
                                        value={secOne?.pincode}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="country"
                                        label="Country"
                                        onChange={(e) => handleSetState(e, 1)}
                                        value={secOne?.country}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="telephone"
                                        label="Telephone number with area code:"
                                        onChange={(e) => handleSetState(e, 1)}
                                        value={secOne?.telephone}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <label
                                        htmlFor="name"
                                        className="block mb-2 text-sm font-medium text-gray-300"
                                    >
                                        Trainee status:
                                    </label>
                                    <select
                                        name="vendorStatus"
                                        onChange={(e) => handleSetState(e, 1)}
                                        value={secOne?.vendorStatus}
                                        disabled={isEditable}
                                        className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        aria-label="Default select"
                                        defaultValue="Individual(Moderator/Freelancer)"
                                        required
                                    >
                                        <option value="" disabled>
                                            Select
                                        </option>
                                        <option value="Public Limited Company">
                                            Public Limited Company
                                        </option>
                                        <option value="Private Limited Company">
                                            Private Limited Company
                                        </option>
                                        <option value="Partnership Firm">
                                            Partnership Firm
                                        </option>
                                        <option value="Sole Proprietor Firm">
                                            Sole Proprietor Firm
                                        </option>
                                        <option value="Local Inter Company">
                                            Local Inter Company
                                        </option>
                                        <option value="Foreign Inter Company">
                                            Foreign Inter Company
                                        </option>
                                        <option value="Individual(Moderator/Freelancer)" selected>
                                            Individual(Moderator/Freelancer)
                                        </option>
                                        <option value="Hindu Undivided Family">
                                            Hindu Undivided Family
                                        </option>
                                        <option value="Employee">Employee</option>
                                        <option value="Association of Persons">
                                            Association of Persons
                                        </option>
                                        <option value="Trust and Body of Individuals">
                                            Trust and Body of Individuals
                                        </option>
                                    </select>
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="registrationNumber"
                                        label="Aadhar Card number"
                                        onChange={(e) => handleSetState(e, 1)}
                                        value={secOne?.aadharcard}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="my-3">
                                <h3 className="text-white text-start text-uppercase text-lg mb-3">
                                    Statute Data
                                </h3>
                                <div className="mt-2 row">
                                    <div className="col-4 mb-4">
                                        <Input
                                            inputName="panno"
                                            label="PAN No. (provide a copy of the PAN Card)"
                                            onChange={(e) => handleSetState(e, 1)}
                                            value={secOne?.panno}
                                            disabled={isEditable}
                                            required
                                        />
                                    </div>
                                    <div className="col-4 mb-4">
                                        <Input
                                            inputName="withHoldingTaxes"
                                            label="Withholding  taxes will be deducted as per the prevailling applicable rates basis the tax declarations/certificates provided to us"
                                            onChange={(e) => handleSetState(e, 1)}
                                            value="Yes"
                                            disabled={isEditable}
                                            required
                                        />
                                    </div>
                                    <div className="col-4 mb-4">
                                        <label
                                            htmlFor="name"
                                            className="block mb-2 text-sm font-medium text-gray-300"
                                        >
                                            Related Party with Omnicom Media Group (Yes/No)
                                        </label>
                                        <select
                                            defaultValue=""
                                            name="relatedwOMG"
                                            onChange={(e) => handleSetState(e, 1)}
                                            value={secOne?.relatedwOMG}
                                            disabled={isEditable}
                                            className="bg-gray-500 shadow-none border-none text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-gray-700 disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            aria-label="Default select"
                                            required
                                        >
                                            <option selected value="" disabled>
                                                Select
                                            </option>
                                            <option selected value="Yes">
                                                Yes
                                            </option>
                                            <option selected value="No">
                                                No
                                            </option>

                                        </select>
                                    </div>
                                    <div className="col-4 mb-4">
                                        <Input
                                            inputName="relatedwOMGRelation"
                                            label="If Related Party with Omnicom Media Group is yes please mention type of relationship"
                                            // onChange={(e) => handleSetState(e, 1)}
                                            value={secOne?.relatedwOMGRelation}
                                            disabled={isEditable}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!isEditable && (
                            <div className="my-3 d-flex w-48 ml-auto">
                                <button
                                    className="btn btn-primary rounded-lg w-100"
                                    type='submit'
                                >
                                    Save
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </form>
    )
}

const SectionTwo = () => {

    return (
        <div className="accordion-item my-2 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secTwo">
                <button
                    className="accordion-button bg-transparent collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSectionTwo"
                    aria-expanded="true"
                    aria-controls="collapseSectionTwo"
                >
                    <h3 className="text-white text-start text-uppercase text-lg ">
                        Section 2 - Trainee Bank Information
                    </h3>
                </button>
            </h2>
            <div
                id="collapseSectionTwo"
                className="accordion-collapse collapse"
                aria-labelledby="secTwo"
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body text-white">
                    <p className="font-light text-justify">
                        <strong>
                            <i className="fa-solid fa-circle-info" /> Note:{" "}
                        </strong>
                        Fill in the bank details by clicking{" "}
                        <a
                            href="/trainee-bankdetails"
                            className="text-primary underline"
                        >
                            here
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

const SectionThree = () => {
    return (
        <div className="accordion-item my-2 rounded-none glass shadow-none">
            <h2 className="accordion-header" id="secFour">
                <button
                    className="accordion-button bg-transparent collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSectionThree"
                    aria-expanded="true"
                    aria-controls="collapseSectionThree"
                >
                    <h3 className="text-white text-start text-uppercase text-lg ">
                        Section 3 - Document Checklist
                    </h3>
                </button>
            </h2>
            <div
                id="collapseSectionThree"
                className="accordion-collapse collapse"
                aria-labelledby="secFour"
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body text-white">
                    <p className="font-light text-justify">
                        <strong>
                            <i className="fa-solid fa-circle-info" /> Note:{" "}
                        </strong>
                        Upload the documents by clicking{" "}
                        <a
                            href="/mt-upload-documents"
                            className="text-primary underline"
                        >
                            here
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

const SectionFour = ({ isEditable, user, isMT }) => {
    const [data, setdata] = useState();
    const [secSix, setSecSix] = useState([]);

    useEffect(() => {
        if (user) {
            onSnapshot(doc(db, "users", user.uid), (doc) => {
                const allData = doc.data()?.vendorDetails;
                setdata(doc.data());
                setSecSix(allData?.sectionSix);
            });
        }
    }, [user]);

    const handleSetState = (e, section) => {
        if (section === 6) {
            setSecSix({
                ...secSix,
                [e.target.name]: e.target.value,
            });
        }
    };

    const handleSaveSection = async (section) => {
        if (section === 6) {
            await updateDoc(doc(db, "users", user.uid), {
                "vendorDetails.sectionSix": secSix
            });
            showToast("success", "Section IV Saved Successfully");
        }
    };
    return (
        <form onSubmit={(e) => { e.preventDefault(); handleSaveSection(6); }}>
            <div className="accordion-item my-2 rounded-none glass shadow-none">
                <h2 className="accordion-header" id="secSix">
                    <button
                        className="accordion-button bg-transparent collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSectionFour"
                        aria-expanded="true"
                        aria-controls="collapseSectionFour"
                    >
                        <h3 className="text-white text-start text-uppercase text-lg ">
                            Section 4 - Declaration
                        </h3>
                    </button>
                </h2>
                <div
                    id="collapseSectionFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="secSix"
                    data-bs-parent="#accordionExample"
                >
                    <div className="accordion-body text-white">
                        <div className="">
                            <div className="row mt-4">
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="serviceProvider"
                                        label="Name of Trainee"
                                        onChange={(e) => handleSetState(e, 6)}
                                        value={secSix?.serviceProvider}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="signatoryName"
                                        label="Name of Signatory"
                                        onChange={(e) => handleSetState(e, 6)}
                                        value={secSix?.signatoryName}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="positionOfSignatory"
                                        label="Position of Signatory"
                                        onChange={(e) => handleSetState(e, 6)}
                                        value={data?.designation}
                                        disabled={isEditable}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="signatoryContactNumber"
                                        label="Contact Number"
                                        onChange={(e) => handleSetState(e, 6)}
                                        disabled={isEditable}
                                        value={data?.phone}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="signatoryEmail"
                                        label="Email ID"
                                        onChange={(e) => handleSetState(e, 6)}
                                        disabled={isEditable}
                                        value={data?.email}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <Input
                                        inputName="place"
                                        label="Place"
                                        onChange={(e) => handleSetState(e, 6)}
                                        disabled={isEditable}
                                        value={secSix?.place}
                                        required
                                    />
                                </div>
                                <div className="col-4 mb-4">
                                    <label
                                        htmlFor="date"
                                        className="block mb-2 text-sm font-medium text-gray-300"
                                    >
                                        Date
                                    </label>
                                    <input
                                        type="date"
                                        name="signatoryDate"
                                        value={secSix?.signatoryDate}
                                        disabled={isEditable}
                                        onChange={(e) => handleSetState(e, 6)}
                                        className="glass shadow-none border-none text-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white disabled:opacity-100 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        autoComplete="none"
                                        required
                                    />
                                </div>
                            </div>
                            {!isEditable && (
                                <div className="my-3 d-flex w-48 ml-auto">
                                    <button
                                        className="btn btn-primary rounded-lg w-100"
                                        type='submit'
                                    >
                                        Save
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export {
    SectionOne, SectionTwo, SectionThree, SectionFour
}